import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab, Box } from '@mui/material'
import EditNoteIcon from '@mui/icons-material/EditNote'
import AddBoxIcon from '@mui/icons-material/AddBox'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import ShowCareCenters from './ShowCareCenters'
import CreateCareCenter from './createCareCenter'
import CareCenterImport from './import'


const propTypes = {
  organizations: PropTypes.arrayOf(
  PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
  })
  ).isRequired,
}

const Dashboard = ({ organizations }) => {
  const [activeTab, setActiveTab] = useState('viewEdit')

  const handleChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  const renderContent = () => {
    switch (activeTab) {
      case 'viewEdit':
        return <ShowCareCenters />
      case 'create':
        return <CreateCareCenter organizations={organizations} />
      case 'import':
        return <CareCenterImport />
      default:
        return <ShowCareCenters />
    }
  }

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleChange} variant="fullWidth" aria-label="dashboard tabs">
          <Tab icon={<EditNoteIcon />} iconPosition="start" label="View/Edit" value="viewEdit" />
          <Tab icon={<AddBoxIcon />} iconPosition="start" label="Create Care Center" value="create" />
          <Tab icon={<AttachFileIcon />} iconPosition="start" label="Import" value="import" />
        </Tabs>
      </Box>
      <Box sx={{ p: 3 }}>
        {renderContent()}
      </Box>
    </div>
  )
}

Dashboard.propTypes = propTypes

export default Dashboard