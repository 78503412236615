import React, { useEffect, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Tooltip from "@mui/material/Tooltip"
import PropTypes from 'prop-types'

const propTypes = {
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired
}

const CopyOrderPreferenceAndSubs = ({ organizations }) => {
  const [organization, setOrganization] = useState('')
  const [providerGroupingOptions, setProviderGroupingOptions] = useState([])
  const [sourceProviderGrouping, setSourceProviderGrouping] = useState('')
  const [targetProviderGrouping, setTargetProviderGrouping] = useState('')

  useEffect(() => {
    if (organization) {
      $.ajax({
        method: 'get',
        url: '/root/provider_grouping/provider_grouping_finder',
        dataType: 'json',
        contentType: 'application/json',
        data: { organization_id: organization },
      })
      .done((response) => {
        setProviderGroupingOptions(Array.isArray(response.provider_grouping) ? response.provider_grouping : [])
      })
      .fail((error) => {
        flash.error(`Error fetching Provider Groupings. ${error.responseJSON.error}`, 5000)
        console.error(error)
      })
    }
  }
  , [organization])

  const handleSubmit = () => {
    $.ajax({
      method: 'POST',
      url: '/root/provider_grouping/copy_order_preferences_and_subscriptions',
      data: {
        source_provider_grouping_id: sourceProviderGrouping.id,
        target_provider_grouping_id: targetProviderGrouping.id
      }
    })
    .done(() => {
        flash.success('Order Preferences and Subscriptions successfully copied', 5000)
    })
    .fail((error) => {
      flash.error(`Error copying Order Preferences and Subscriptions. ${error.responseJSON.error}`, 5000)
      console.error(error)
    })
  }

    return (
      <form onSubmit={handleSubmit}>
        <Autocomplete
          id="organization-options"
          options={organizations}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Organization" value={organization} InputProps={{...params.InputProps, disableUnderline: true}} variant="standard"/>}
          onChange={(event, value) => setOrganization(value.id)}
      />
        <Tooltip title="Source: The provider group's order preferences to be copied from." arrow>
        <Autocomplete
          id="source-provider-grouping-options"
          options={providerGroupingOptions}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Source Provider Grouping" value={sourceProviderGrouping} InputProps={{...params.InputProps, disableUnderline: true}} variant="standard"/>}
          onChange={(event, value) => setSourceProviderGrouping(value.id)}
        />
        </Tooltip>
        <Tooltip title="Target: The provider group's order preferences to be updated." arrow>
        <Autocomplete
          id="target-provider-grouping-options"
          options={providerGroupingOptions}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Target Provider Grouping" value={targetProviderGrouping} InputProps={{...params.InputProps, disableUnderline: true}} variant="standard"/>}
          onChange={(event, value) => setTargetProviderGrouping(value.id)}
        />
        </Tooltip>
        <button type="submit">Submit</button>
      </form>
    )
        
}

CopyOrderPreferenceAndSubs.propTypes = propTypes

export default CopyOrderPreferenceAndSubs