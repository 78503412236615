import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Tabs, Tab, Box } from '@mui/material'
import EditNoteIcon from '@mui/icons-material/EditNote'
import AddBoxIcon from '@mui/icons-material/AddBox'
import CreateProviderGrouping from './createProviderGrouping'
import CopyOrderPreferenceAndSubs from './copyOrderPreferenceAndSubs'

const propTypes = {
  organizations: PropTypes.arrayOf(
  PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
  })
  ).isRequired
}

const Dashboard = ({ organizations }) => {
  const [activeTab, setActiveTab] = useState('create')

  const handleChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  const renderContent = () => {
    switch (activeTab) {
      case 'create':
        return <CreateProviderGrouping organizations={organizations} />
      case 'edit':
        return <CopyOrderPreferenceAndSubs organizations={organizations}/>
      default:
        return <CreateProviderGrouping organizations={organizations} />
    }
  }

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleChange} variant="fullWidth" aria-label="dashboard tabs">
          <Tab icon={<AddBoxIcon />} iconPosition="start" label="Create Provider Group" value="create" />
          <Tab icon={<EditNoteIcon />} iconPosition="start" label="Copy Order Preference And Subs" value="edit" />
        </Tabs>
      </Box>
      <Box sx={{ p: 3 }}>
        {renderContent()}
      </Box>
    </div>
  )
}

Dashboard.propTypes = propTypes

export default Dashboard