import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import { headerFilteringStateInitializer } from '@mui/x-data-grid/internals'

const propTypes = {
  careCenter: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    departmentIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    approvals: PropTypes.string.isRequired,
    organizationId: PropTypes.number.isRequired,
    providerGroupingId: PropTypes.number,
  }).isRequired,
}

const EditCareCenter = ({ careCenter }) => {
  const [name, setName] = useState(careCenter.name)
  const [phoneNumber, setPhoneNumber] = useState(careCenter.phoneNumber)
  const [departmentIds, setDepartmentIds] = useState(careCenter.departmentIds)
  const [approvals, setApprovals] = useState(careCenter.approvals)

  const handleSubmit = event => {
    event.preventDefault()
    const formData = new FormData()
    formData.append("id", careCenter.id)
    formData.append("name", name)
    formData.append("phone_number", phoneNumber)
    formData.append("department_ids", departmentIds)
    formData.append("approvals", approvals)

    $.ajax({
      method: 'POST',
      url: `/root/care_center/update`,
      contentType: false,
      processData: false,
      data: formData,
    })
    .done(() => {
      flash.success(`Care Center id: ${careCenter.id} successfully updated`, 50000)
      // refresh the page after flash message is displayed
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    })
    .fail((error) => {
      flash.error(`Error updating your Care Center id: ${careCenter.id}. ${error.responseJSON.error}`, 50000)
      console.error(error)
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Name"
        value={name}
        onChange={event => setName(event.target.value)}
        required
        fullWidth
        InputProps={{disableUnderline: true}}
        variant="standard"
      />
      <TextField
        label="Phone Number"
        value={phoneNumber}
        onChange={event => setPhoneNumber(event.target.value)}
        required
        fullWidth
        InputProps={{disableUnderline: true}}
        variant="standard"
      />
      <TextField
        label="Department Ids"
        value={departmentIds}
        onChange={event => setDepartmentIds(event.target.value)}
        fullWidth
        disabled
        InputProps={{disableUnderline: true}}
        variant="standard"
      />
      <TextField
        label="Approvals"
        value={approvals}
        onChange={event => setApprovals(event.target.value)}
        required
        fullWidth
        InputProps={{disableUnderline: true}}
        variant="standard"
      />
      <TextField
        label="Organization ID"
        value={careCenter.organizationId}
        disabled
        fullWidth
        InputProps={{disableUnderline: true}}
        variant="standard"
      />
      <TextField
        label="Provider Grouping ID"
        value={careCenter.providerGroupingId}
        disabled
        fullWidth
        InputProps={{disableUnderline: true}}
        variant="standard"
      />
      <button type="submit">Submit</button>
    </form>
  )
}

EditCareCenter.propTypes = propTypes

export default EditCareCenter