import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Box, Button} from '@mui/material'
import LoadingSkeleton from '../../loadingSkeleton/LoadingSkeleton'
import EditCareCenter from './editCareCenter'

const ShowCareCenters = () => {
  const [data, setData] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [selectedCareCenter, setSelectedCareCenter] = useState(null)


  const handleEdit = (careCenter) => {
    setSelectedCareCenter(careCenter)
  }

  const columns = [
    { field: 'name', headerName: 'Name', width: 300 },
    { field: 'organizationId', headerName: 'Organization ID', width: 125 },
    { field: 'providerGroupingId', headerName: 'Provider grouping ID', width: 125 },
    { field: 'phoneNumber', headerName: 'Number', width: 125, filterable: false },
    { field: 'approvals', headerName: 'Approvals', width: 225 },
    {
      field: 'edit',
      headerName: 'Edit',
      filterable: false,
      width: 100,
      renderCell: (params) => (
        <Button variant="text" color="primary" onClick={() => handleEdit(params.row)}>Edit</Button>
      ),
    },
  ]

  useEffect(() => {
    const fetchData = async () => {
      $.ajax({
        method: 'get',
        url: '/root/care_center_list',
        dataType: 'json',
        contentType: 'application/json',
      })
        .done((response) => {
          setData(response.care_centers)
          setLoading(false)
        })
        .fail((errors) => {
          setError(errors)
          console.error(errors)
        })
    }
    fetchData()
  }, [])

  const getRowId = (row) => row.id

  return (
    <div>
      {selectedCareCenter ? (
        <EditCareCenter careCenter={selectedCareCenter} />
      ) : (
        <div style={{ height: '100%', width: '100%' }}>
          {loading ? (
            <LoadingSkeleton />
          ) : (
            <Box sx={{ width: '100%' }}>
              <DataGrid
                rows={data}
                columns={columns}
                getRowId={getRowId}
                pageSize={5}
              />
            </Box>
          )}
        </div>
      )}
    </div>
  )
}

export default ShowCareCenters